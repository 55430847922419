import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import {IRPStore} from "../../app/rp_reducer";
import {InvestmentOfferCountrySlug, InvestmentOfferTypeSlug, SpainInvestmentOfferSlug} from "../../investment_offer/constants/investment_offer_constants";
import {Country} from "../../region/types/Country";

export const isSpainInvestmentCategory = (subcategory: string): boolean => {
    const spainCategories: string[] = [...Object.values(SpainInvestmentOfferSlug), InvestmentOfferCountrySlug.SPAIN];

    return spainCategories.includes(subcategory);
};
export const useSearchParamAbroadCountry = () => {
    const investmentCategories = useSelector((state: IRPStore) => state.investmentOffer.categories);
    const [country, setCountry] = useState<Country | null>(null);

    useEffect(() => {
        if (investmentCategories?.category === InvestmentOfferTypeSlug.ABROAD) {
            // TODO: replace this in the future if we have more countries
            const isSpainCategory = investmentCategories.subcategory && isSpainInvestmentCategory(investmentCategories.subcategory);

            setCountry(isSpainCategory ? Country.SPAIN : null);
            return;
        }

        setCountry(Country.POLAND);
    }, [investmentCategories?.category]);

    return country;
};
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import {IRPStore} from "../../app/rp_reducer";
import {InvestmentOfferCountrySlug, InvestmentOfferTypeSlug, SpainInvestmentOfferSlug} from "../../investment_offer/constants/investment_offer_constants";
import {Country} from "../../region/types/Country";

export const isSpainInvestmentCategory = (subcategory: string): boolean => {
    const spainCategories: string[] = [...Object.values(SpainInvestmentOfferSlug), InvestmentOfferCountrySlug.SPAIN];

    return spainCategories.includes(subcategory);
};
export const useSearchParamAbroadCountry = () => {
    const investmentCategories = useSelector((state: IRPStore) => state.investmentOffer.categories);
    const [country, setCountry] = useState<Country | null>(null);

    useEffect(() => {
        if (investmentCategories?.category === InvestmentOfferTypeSlug.ABROAD) {
            // TODO: replace this in the future if we have more countries
            const isSpainCategory = investmentCategories.subcategory && isSpainInvestmentCategory(investmentCategories.subcategory);

            setCountry(isSpainCategory ? Country.SPAIN : null);
            return;
        }

        setCountry(Country.POLAND);
    }, [investmentCategories?.category]);

    return country;
};
