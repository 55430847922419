import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {isEqual} from "@pg-mono/nodash";

export interface ISearchFormState {
    isLocationSearchOpen: boolean;
    interactiveMapOriginRegions: (string | number)[] | null;
    interactiveMapRegionSelection: (string | number)[] | null;
}

const initialState: ISearchFormState = {
    isLocationSearchOpen: false,
    interactiveMapOriginRegions: null,
    interactiveMapRegionSelection: null
};

export const searchFormSlice = createSlice({
    name: "searchForm",
    initialState,
    reducers: {
        openLocationSearch: (state) => {
            state.isLocationSearchOpen = true;
        },
        closeLocationSearch: (state) => {
            state.isLocationSearchOpen = false;
            state.interactiveMapRegionSelection = null;
        },
        updateInteractiveMapOriginRegions: (state, action: PayloadAction<(string | number)[]>) => {
            if (!isEqual(state.interactiveMapOriginRegions, action.payload)) {
                state.interactiveMapOriginRegions = [...action.payload];
            }
        },
        updateInteractiveMapRegionSelection: (state, action: PayloadAction<(string | number)[]>) => {
            state.interactiveMapRegionSelection = [...action.payload];
        }
    }
});

export const searchFormActions = searchFormSlice.actions;
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {isEqual} from "@pg-mono/nodash";

export interface ISearchFormState {
    isLocationSearchOpen: boolean;
    interactiveMapOriginRegions: (string | number)[] | null;
    interactiveMapRegionSelection: (string | number)[] | null;
}

const initialState: ISearchFormState = {
    isLocationSearchOpen: false,
    interactiveMapOriginRegions: null,
    interactiveMapRegionSelection: null
};

export const searchFormSlice = createSlice({
    name: "searchForm",
    initialState,
    reducers: {
        openLocationSearch: (state) => {
            state.isLocationSearchOpen = true;
        },
        closeLocationSearch: (state) => {
            state.isLocationSearchOpen = false;
            state.interactiveMapRegionSelection = null;
        },
        updateInteractiveMapOriginRegions: (state, action: PayloadAction<(string | number)[]>) => {
            if (!isEqual(state.interactiveMapOriginRegions, action.payload)) {
                state.interactiveMapOriginRegions = [...action.payload];
            }
        },
        updateInteractiveMapRegionSelection: (state, action: PayloadAction<(string | number)[]>) => {
            state.interactiveMapRegionSelection = [...action.payload];
        }
    }
});

export const searchFormActions = searchFormSlice.actions;
