import {useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import type {Dispatch} from "redux";

import type {IFormValuesAction} from "@pg-mono/form";
import {FormFieldType} from "@pg-mono/form/src/form_interfaces";
import {IAction} from "@pg-mono/request-state";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPStore} from "../../app/rp_reducer";
import {offerUrlBuilder} from "../../offer/helpers/friendly_offer_list/offer_url_builder";
import {isSpainInvestmentCategory} from "../../search/hooks/use_search_param_abroad_country";
import {ViewType} from "../../view_type/ViewType";
import {
    InvestmentOfferCategory,
    InvestmentOfferCountrySlug,
    InvestmentOfferSubCategory,
    InvestmentOfferTypeSlug
} from "../constants/investment_offer_constants";
import {investmentOfferListFilterFormActions} from "../list/actions/investment_offer_list_filter_form_actions";
import {redirectClientToInvestmentOfferCategoryUrl} from "../list/utils/redirect_client_to_category_url";

export const investmentOfferListFiltersFields = {
    category: FormFieldType.Select,
    subcategory: FormFieldType.Select
};

export interface IInvestmentOfferListFiltersFields {
    category: InvestmentOfferCategory;
    subcategory: InvestmentOfferSubCategory;
}

type InvestmentOfferListFiltersNewValuesType = Partial<IInvestmentOfferListFiltersFields>;

interface IHook {
    formValues: IInvestmentOfferListFiltersFields;
    onChange: (newFormValues: InvestmentOfferListFiltersNewValuesType, redirect: boolean) => void;
    onSave: () => void;
    onStoreFormValues: (formValues: InvestmentOfferListFiltersNewValuesType | null) => void;
    resetFilters: () => void;
}

export const useInvestmentOfferFilters = (): IHook => {
    const dispatch: Dispatch<IFormValuesAction<unknown> | IAction> = useDispatch();
    const history = useHistory();
    const view = useSelector((state: IRPStore) => state.viewType.current);
    const isMap = view === ViewType.FOR_INVESTMENT_LIST_MAP;

    // In order to submit filters change with delay (on mobile) we need to store initial filter values.
    const storedFormValues = useRef<InvestmentOfferListFiltersNewValuesType | null>(null);

    const formValues = useSelector((state: IRPStore) => state.investmentOffer.list.form.formValues);

    const onChange = (newFormValues: InvestmentOfferListFiltersNewValuesType, redirect: boolean) => {
        dispatch(investmentOfferListFilterFormActions.update(newFormValues));

        if (redirect) {
            onRedirect(newFormValues);
        }
    };

    const onRedirect = (newFormValues: InvestmentOfferListFiltersNewValuesType) => {
        const isAbroadSubcategory = newFormValues.subcategory && isSpainInvestmentCategory(newFormValues.subcategory);

        // TODO think how to pass country instead of using InvestmentOfferCountrySlug in future
        if (isAbroadSubcategory && newFormValues.subcategory) {
            const friendlySlug = offerUrlBuilder({region: ""}).friendlySlug;
            const abroadSubcategoryLink = rpAppLink.offer.listFriendlyAbroad({
                friendlySlug: friendlySlug,
                country: InvestmentOfferCountrySlug.SPAIN,
                abroadRegionSlug: newFormValues.subcategory
            });
            return (window.location.href = abroadSubcategoryLink);
        }
        redirectClientToInvestmentOfferCategoryUrl(newFormValues, storedFormValues.current || formValues, history, {
            category: rpAppLink.investmentOffer.investmentCategory[isMap ? "map" : "base"],
            subcategory: rpAppLink.investmentOffer.investmentCategory.subcategory[isMap ? "map" : "base"]
        });
    };

    const onStoreFormValues = (formValues: InvestmentOfferListFiltersNewValuesType | null) => {
        storedFormValues.current = formValues;
    };

    const resetFilters = () => {
        dispatch(investmentOfferListFilterFormActions.clear());
        if (storedFormValues.current?.category === InvestmentOfferTypeSlug.ABROAD) {
            onRedirect({category: InvestmentOfferTypeSlug.ABROAD});
            return;
        }
        onRedirect({category: InvestmentOfferTypeSlug.INVESTMENT});
    };

    return {
        formValues,
        onChange,
        onSave: () => onRedirect(formValues),
        onStoreFormValues,
        resetFilters
    };
};
import {useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import type {Dispatch} from "redux";

import type {IFormValuesAction} from "@pg-mono/form";
import {FormFieldType} from "@pg-mono/form/src/form_interfaces";
import {IAction} from "@pg-mono/request-state";
import {rpAppLink} from "@pg-mono/rp-routes";

import {IRPStore} from "../../app/rp_reducer";
import {offerUrlBuilder} from "../../offer/helpers/friendly_offer_list/offer_url_builder";
import {isSpainInvestmentCategory} from "../../search/hooks/use_search_param_abroad_country";
import {ViewType} from "../../view_type/ViewType";
import {
    InvestmentOfferCategory,
    InvestmentOfferCountrySlug,
    InvestmentOfferSubCategory,
    InvestmentOfferTypeSlug
} from "../constants/investment_offer_constants";
import {investmentOfferListFilterFormActions} from "../list/actions/investment_offer_list_filter_form_actions";
import {redirectClientToInvestmentOfferCategoryUrl} from "../list/utils/redirect_client_to_category_url";

export const investmentOfferListFiltersFields = {
    category: FormFieldType.Select,
    subcategory: FormFieldType.Select
};

export interface IInvestmentOfferListFiltersFields {
    category: InvestmentOfferCategory;
    subcategory: InvestmentOfferSubCategory;
}

type InvestmentOfferListFiltersNewValuesType = Partial<IInvestmentOfferListFiltersFields>;

interface IHook {
    formValues: IInvestmentOfferListFiltersFields;
    onChange: (newFormValues: InvestmentOfferListFiltersNewValuesType, redirect: boolean) => void;
    onSave: () => void;
    onStoreFormValues: (formValues: InvestmentOfferListFiltersNewValuesType | null) => void;
    resetFilters: () => void;
}

export const useInvestmentOfferFilters = (): IHook => {
    const dispatch: Dispatch<IFormValuesAction<unknown> | IAction> = useDispatch();
    const history = useHistory();
    const view = useSelector((state: IRPStore) => state.viewType.current);
    const isMap = view === ViewType.FOR_INVESTMENT_LIST_MAP;

    // In order to submit filters change with delay (on mobile) we need to store initial filter values.
    const storedFormValues = useRef<InvestmentOfferListFiltersNewValuesType | null>(null);

    const formValues = useSelector((state: IRPStore) => state.investmentOffer.list.form.formValues);

    const onChange = (newFormValues: InvestmentOfferListFiltersNewValuesType, redirect: boolean) => {
        dispatch(investmentOfferListFilterFormActions.update(newFormValues));

        if (redirect) {
            onRedirect(newFormValues);
        }
    };

    const onRedirect = (newFormValues: InvestmentOfferListFiltersNewValuesType) => {
        const isAbroadSubcategory = newFormValues.subcategory && isSpainInvestmentCategory(newFormValues.subcategory);

        // TODO think how to pass country instead of using InvestmentOfferCountrySlug in future
        if (isAbroadSubcategory && newFormValues.subcategory) {
            const friendlySlug = offerUrlBuilder({region: ""}).friendlySlug;
            const abroadSubcategoryLink = rpAppLink.offer.listFriendlyAbroad({
                friendlySlug: friendlySlug,
                country: InvestmentOfferCountrySlug.SPAIN,
                abroadRegionSlug: newFormValues.subcategory
            });
            return (window.location.href = abroadSubcategoryLink);
        }
        redirectClientToInvestmentOfferCategoryUrl(newFormValues, storedFormValues.current || formValues, history, {
            category: rpAppLink.investmentOffer.investmentCategory[isMap ? "map" : "base"],
            subcategory: rpAppLink.investmentOffer.investmentCategory.subcategory[isMap ? "map" : "base"]
        });
    };

    const onStoreFormValues = (formValues: InvestmentOfferListFiltersNewValuesType | null) => {
        storedFormValues.current = formValues;
    };

    const resetFilters = () => {
        dispatch(investmentOfferListFilterFormActions.clear());
        if (storedFormValues.current?.category === InvestmentOfferTypeSlug.ABROAD) {
            onRedirect({category: InvestmentOfferTypeSlug.ABROAD});
            return;
        }
        onRedirect({category: InvestmentOfferTypeSlug.INVESTMENT});
    };

    return {
        formValues,
        onChange,
        onSave: () => onRedirect(formValues),
        onStoreFormValues,
        resetFilters
    };
};
