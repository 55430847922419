import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface IFilterShown {
    wasSeen: boolean;
}

export const filtersShownSlice = createSlice({
    name: "filtersShown",
    initialState: {wasSeen: false},
    reducers: {
        setFiltersShown: (state, action: PayloadAction<IFilterShown>) => {
            state.wasSeen = action.payload.wasSeen;
        }
    }
});

export const {setFiltersShown} = filtersShownSlice.actions;
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface IFilterShown {
    wasSeen: boolean;
}

export const filtersShownSlice = createSlice({
    name: "filtersShown",
    initialState: {wasSeen: false},
    reducers: {
        setFiltersShown: (state, action: PayloadAction<IFilterShown>) => {
            state.wasSeen = action.payload.wasSeen;
        }
    }
});

export const {setFiltersShown} = filtersShownSlice.actions;
