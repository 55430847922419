import React, {ReactNode} from "react";
import {css, Theme} from "@emotion/react";

import {calculateRemSize, flex, flexDirection, p, w100} from "@pg-design/helpers-css";
import {InputWrapper} from "@pg-design/inputs-module";

import {ISearchAutocompleteInputProps, SearchAutocompleteInput} from "./SearchAutocompleteInput";

export enum SlotOrderType {
    ROW = "row",
    ROW_REVERSE = "row-reverse"
}

interface IProps extends ISearchAutocompleteInputProps {
    slot?: ReactNode;
    slotOrder?: SlotOrderType;
}

export const SearchAutocompleteInputWithSlot = (props: IProps) => {
    const {slot, slotOrder, ...restProps} = props;

    if (slot) {
        return (
            <div css={[flex("flex-start"), flexDirection(slotOrder || "row"), w100]}>
                <InputWrapper css={[slotWrapperStyle, slotOrder && slotOrder === SlotOrderType.ROW_REVERSE ? rightInputWrapperStyle : leftInputWrapperStyle]}>
                    {slot}
                </InputWrapper>
                <SearchAutocompleteInput
                    {...restProps}
                    inputContainerCss={[
                        props.inputContainerCss,
                        slotOrder && slotOrder === SlotOrderType.ROW_REVERSE ? leftInputWrapperStyle : rightInputWrapperStyle
                    ]}
                />
            </div>
        );
    }

    return <SearchAutocompleteInput {...restProps} inputContainerCss={[props.inputContainerCss, props.isInputWithDistanceFilter && leftInputWrapperStyle]} />;
};

const leftInputWrapperStyle = css`
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    flex: 0 0 auto;
`;

const rightInputWrapperStyle = css`
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: 0;
    flex: 0 0 auto;
`;

const slotWrapperStyle = (theme: Theme) => css`
    height: 50px;
    ${p(0)};
    position: relative;

    &:focus {
        outline: none;
    }

    @media (min-width: ${theme.breakpoints.lg}) {
        width: ${calculateRemSize(15)};
    }
`;
import React, {ReactNode} from "react";
import {css, Theme} from "@emotion/react";

import {calculateRemSize, flex, flexDirection, p, w100} from "@pg-design/helpers-css";
import {InputWrapper} from "@pg-design/inputs-module";

import {ISearchAutocompleteInputProps, SearchAutocompleteInput} from "./SearchAutocompleteInput";

export enum SlotOrderType {
    ROW = "row",
    ROW_REVERSE = "row-reverse"
}

interface IProps extends ISearchAutocompleteInputProps {
    slot?: ReactNode;
    slotOrder?: SlotOrderType;
}

export const SearchAutocompleteInputWithSlot = (props: IProps) => {
    const {slot, slotOrder, ...restProps} = props;

    if (slot) {
        return (
            <div css={[flex("flex-start"), flexDirection(slotOrder || "row"), w100]}>
                <InputWrapper css={[slotWrapperStyle, slotOrder && slotOrder === SlotOrderType.ROW_REVERSE ? rightInputWrapperStyle : leftInputWrapperStyle]}>
                    {slot}
                </InputWrapper>
                <SearchAutocompleteInput
                    {...restProps}
                    inputContainerCss={[
                        props.inputContainerCss,
                        slotOrder && slotOrder === SlotOrderType.ROW_REVERSE ? leftInputWrapperStyle : rightInputWrapperStyle
                    ]}
                />
            </div>
        );
    }

    return <SearchAutocompleteInput {...restProps} inputContainerCss={[props.inputContainerCss, props.isInputWithDistanceFilter && leftInputWrapperStyle]} />;
};

const leftInputWrapperStyle = css`
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    flex: 0 0 auto;
`;

const rightInputWrapperStyle = css`
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-left: 0;
    flex: 0 0 auto;
`;

const slotWrapperStyle = (theme: Theme) => css`
    height: 50px;
    ${p(0)};
    position: relative;

    &:focus {
        outline: none;
    }

    @media (min-width: ${theme.breakpoints.lg}) {
        width: ${calculateRemSize(15)};
    }
`;
