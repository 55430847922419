import {isEqual} from "@pg-mono/nodash";

import {gtmEventOfferListSearch, gtmEventOfferListSort} from "../../../tracking/google_tag_manager/gtm_event_ui";
import {ILatestQuery} from "../../../utils/latest_query";

export const handleFetchOfferListGTMSearchEvents = (latestQuery: ILatestQuery, query: Record<string, string | string[]>) => {
    !(isEqual(latestQuery.rooms_0, query.rooms_0) && isEqual(latestQuery.rooms_1, query.rooms_1)) && gtmEventOfferListSearch("pokoje");
    !(isEqual(latestQuery.area_0, query.area_0) && isEqual(latestQuery.area_1, query.area_1)) && gtmEventOfferListSearch("powierzchnia");
    !(isEqual(latestQuery.price_0, query.price_0) && isEqual(latestQuery.price_1, query.price_1)) && gtmEventOfferListSearch("cena");
    !isEqual(latestQuery.floor_choices, query.floor_choices) && gtmEventOfferListSearch("piętro");
    !isEqual(latestQuery.construction_end_date, query.construction_end_date) && gtmEventOfferListSearch("termin oddania");
};

export const getAnalyticsSortDescription = (sort: string): string | undefined => {
    switch (sort) {
        case "create_date":
        case "2":
            return "najnowszych";
        case "price":
        case "3":
            return "ceny nominalnej";
        case "price_m2":
        case "4":
            return "ceny za m2";
        case "construction_date_range":
        case "5":
            return "terminu oddania";
        default:
            return undefined;
    }
};

export const handleFetchOfferListGTMSortEvents = (latestQuery: ILatestQuery, query: Record<string, string | string[]>) => {
    !isEqual(latestQuery.sort, query.sort) && gtmEventOfferListSort(getAnalyticsSortDescription(query.sort as string) ?? "polecanych");
};
import {isEqual} from "@pg-mono/nodash";

import {gtmEventOfferListSearch, gtmEventOfferListSort} from "../../../tracking/google_tag_manager/gtm_event_ui";
import {ILatestQuery} from "../../../utils/latest_query";

export const handleFetchOfferListGTMSearchEvents = (latestQuery: ILatestQuery, query: Record<string, string | string[]>) => {
    !(isEqual(latestQuery.rooms_0, query.rooms_0) && isEqual(latestQuery.rooms_1, query.rooms_1)) && gtmEventOfferListSearch("pokoje");
    !(isEqual(latestQuery.area_0, query.area_0) && isEqual(latestQuery.area_1, query.area_1)) && gtmEventOfferListSearch("powierzchnia");
    !(isEqual(latestQuery.price_0, query.price_0) && isEqual(latestQuery.price_1, query.price_1)) && gtmEventOfferListSearch("cena");
    !isEqual(latestQuery.floor_choices, query.floor_choices) && gtmEventOfferListSearch("piętro");
    !isEqual(latestQuery.construction_end_date, query.construction_end_date) && gtmEventOfferListSearch("termin oddania");
};

export const getAnalyticsSortDescription = (sort: string): string | undefined => {
    switch (sort) {
        case "create_date":
        case "2":
            return "najnowszych";
        case "price":
        case "3":
            return "ceny nominalnej";
        case "price_m2":
        case "4":
            return "ceny za m2";
        case "construction_date_range":
        case "5":
            return "terminu oddania";
        default:
            return undefined;
    }
};

export const handleFetchOfferListGTMSortEvents = (latestQuery: ILatestQuery, query: Record<string, string | string[]>) => {
    !isEqual(latestQuery.sort, query.sort) && gtmEventOfferListSort(getAnalyticsSortDescription(query.sort as string) ?? "polecanych");
};
