import {Dispatch} from "redux";

import {getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {IOfferDetail} from "../../types/IOfferDetail";

const FETCH_MAP_OFFER_DETAIL = "map/FETCH_MAP_OFFER_DETAIL";
export const fetchMapSelectedOfferTypes = createRequestActionTypes(FETCH_MAP_OFFER_DETAIL);

export const fetchMapSelectedOffer = (offerId: number) => (dispatch: Dispatch) => {
    dispatch({type: fetchMapSelectedOfferTypes.start});
    const url = apiV2Link.offer.detail(Scenario.OFFER_DETAIL_MAP, {offerId});
    return getRequest({}, url).then((result: IOfferDetail) => {
        dispatch({type: fetchMapSelectedOfferTypes.success, result});
        return result;
    });
};

export const resetFetchMapSelectedOffer = () => (dispatch: Dispatch) => {
    dispatch({type: fetchMapSelectedOfferTypes.reset});
};
import {Dispatch} from "redux";

import {getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {IOfferDetail} from "../../types/IOfferDetail";

const FETCH_MAP_OFFER_DETAIL = "map/FETCH_MAP_OFFER_DETAIL";
export const fetchMapSelectedOfferTypes = createRequestActionTypes(FETCH_MAP_OFFER_DETAIL);

export const fetchMapSelectedOffer = (offerId: number) => (dispatch: Dispatch) => {
    dispatch({type: fetchMapSelectedOfferTypes.start});
    const url = apiV2Link.offer.detail(Scenario.OFFER_DETAIL_MAP, {offerId});
    return getRequest({}, url).then((result: IOfferDetail) => {
        dispatch({type: fetchMapSelectedOfferTypes.success, result});
        return result;
    });
};

export const resetFetchMapSelectedOffer = () => (dispatch: Dispatch) => {
    dispatch({type: fetchMapSelectedOfferTypes.reset});
};
