import {FetchBaseQueryError} from "@reduxjs/toolkit/query";

import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../../app/api/rp_api";
import {IRegionListMap} from "../../../region/types/IRegionListMap";
import {RegionType} from "../../../region/types/RegionType";
import {getIgnoredNotifyResponse} from "../../../store/utils/get_ignored_notify_response";
import {ALLOWED_CITIES_FOR_REGIONS_SELECTION_ON_MAP} from "../constants/allowed_cities_for_regions_selection_on_map";
import {DISABLE_DISTRICTS_AND_SURROUNDINGS_MAP_FOR_REGIONS} from "../constants/disable_districts_and_surroundings_map_for_regions";
import {DISABLE_DISTRICTS_OR_SURROUNDINGS_FOR_MAP_VIEW} from "../constants/disable_districts_or_surroundings_for_map_view";

const multiRegionListLink = apiV2ListLink.region.list(Scenario.REGION_LIST_MAP);

interface IRequestParams {
    parent: number;
}

interface IResponse {
    results: IRegionListMap[];
}

const RESULTS_LIMIT = 100;

export const getMapRegionDistrictsAndSurroundings = rpApi.injectEndpoints({
    endpoints: (build) => ({
        getMapRegionDistrictsAndSurroundings: build.query<IResponse, IRequestParams>({
            queryFn: async (queryParams, api, _extraOptions, fetchBaseQuery) => {
                if (ALLOWED_CITIES_FOR_REGIONS_SELECTION_ON_MAP !== null && !ALLOWED_CITIES_FOR_REGIONS_SELECTION_ON_MAP?.includes(queryParams.parent)) {
                    return {data: {results: []}};
                }

                if (DISABLE_DISTRICTS_AND_SURROUNDINGS_MAP_FOR_REGIONS.includes(queryParams.parent)) {
                    return {data: {results: []}};
                }

                const queryResponse = (await fetchBaseQuery({
                    url: multiRegionListLink,
                    method: "GET",
                    params: {
                        parent: queryParams.parent,
                        type: [RegionType.DISTRICT, RegionType.SURROUNDINGS],
                        page_size: RESULTS_LIMIT
                    }
                })) as {data: IResponse; error?: FetchBaseQueryError};

                if (queryResponse.error) {
                    return {
                        error: getIgnoredNotifyResponse(queryResponse.error, [400, 404])
                    };
                }

                return {
                    data: {
                        results: queryResponse.data.results.filter((record) => {
                            return !DISABLE_DISTRICTS_OR_SURROUNDINGS_FOR_MAP_VIEW.includes(record.id);
                        })
                    }
                };
            }
        })
    })
});

export const {useGetMapRegionDistrictsAndSurroundingsQuery} = getMapRegionDistrictsAndSurroundings;
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";

import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../../app/api/rp_api";
import {IRegionListMap} from "../../../region/types/IRegionListMap";
import {RegionType} from "../../../region/types/RegionType";
import {getIgnoredNotifyResponse} from "../../../store/utils/get_ignored_notify_response";
import {ALLOWED_CITIES_FOR_REGIONS_SELECTION_ON_MAP} from "../constants/allowed_cities_for_regions_selection_on_map";
import {DISABLE_DISTRICTS_AND_SURROUNDINGS_MAP_FOR_REGIONS} from "../constants/disable_districts_and_surroundings_map_for_regions";
import {DISABLE_DISTRICTS_OR_SURROUNDINGS_FOR_MAP_VIEW} from "../constants/disable_districts_or_surroundings_for_map_view";

const multiRegionListLink = apiV2ListLink.region.list(Scenario.REGION_LIST_MAP);

interface IRequestParams {
    parent: number;
}

interface IResponse {
    results: IRegionListMap[];
}

const RESULTS_LIMIT = 100;

export const getMapRegionDistrictsAndSurroundings = rpApi.injectEndpoints({
    endpoints: (build) => ({
        getMapRegionDistrictsAndSurroundings: build.query<IResponse, IRequestParams>({
            queryFn: async (queryParams, api, _extraOptions, fetchBaseQuery) => {
                if (ALLOWED_CITIES_FOR_REGIONS_SELECTION_ON_MAP !== null && !ALLOWED_CITIES_FOR_REGIONS_SELECTION_ON_MAP?.includes(queryParams.parent)) {
                    return {data: {results: []}};
                }

                if (DISABLE_DISTRICTS_AND_SURROUNDINGS_MAP_FOR_REGIONS.includes(queryParams.parent)) {
                    return {data: {results: []}};
                }

                const queryResponse = (await fetchBaseQuery({
                    url: multiRegionListLink,
                    method: "GET",
                    params: {
                        parent: queryParams.parent,
                        type: [RegionType.DISTRICT, RegionType.SURROUNDINGS],
                        page_size: RESULTS_LIMIT
                    }
                })) as {data: IResponse; error?: FetchBaseQueryError};

                if (queryResponse.error) {
                    return {
                        error: getIgnoredNotifyResponse(queryResponse.error, [400, 404])
                    };
                }

                return {
                    data: {
                        results: queryResponse.data.results.filter((record) => {
                            return !DISABLE_DISTRICTS_OR_SURROUNDINGS_FOR_MAP_VIEW.includes(record.id);
                        })
                    }
                };
            }
        })
    })
});

export const {useGetMapRegionDistrictsAndSurroundingsQuery} = getMapRegionDistrictsAndSurroundings;
