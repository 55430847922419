import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";

interface ISearchGuideTourStep {
    id: number;
}

export interface ISearchGuideTour {
    [key: string]:
        | {
              currentStepIndex: number;
              completed: boolean;
              steps: ISearchGuideTourStep[];
          }
        | undefined;
}

const initialState: ISearchGuideTour = {};

export const searchGuideTourSlice = createSlice({
    name: "searchGuideTour",
    initialState,
    reducers: {
        initializeTour: (state, action: PayloadAction<{name: string; steps: ISearchGuideTourStep[]}>) => {
            state[action.payload.name] = {
                currentStepIndex: 0,
                completed: false,
                steps: structuredClone(action.payload.steps)
            };
        },
        completeStep: (state, action: PayloadAction<string>) => {
            const tourName = action.payload;

            if (!state[tourName]) {
                notifyBugsnag(new Error("Unknown search guide tour name"), `Unknown search guide tour: ${tourName}`);
                return;
            }

            state[tourName].currentStepIndex++;

            if (state[tourName].currentStepIndex >= state[tourName].steps.length) {
                state[tourName].currentStepIndex = NaN;
                state[tourName].completed = true;
            }
        }
    }
});

export const {completeStep, initializeTour} = searchGuideTourSlice.actions;
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {notifyBugsnag} from "../../errors/bugsnag/notify_bugsnag";

interface ISearchGuideTourStep {
    id: number;
}

export interface ISearchGuideTour {
    [key: string]:
        | {
              currentStepIndex: number;
              completed: boolean;
              steps: ISearchGuideTourStep[];
          }
        | undefined;
}

const initialState: ISearchGuideTour = {};

export const searchGuideTourSlice = createSlice({
    name: "searchGuideTour",
    initialState,
    reducers: {
        initializeTour: (state, action: PayloadAction<{name: string; steps: ISearchGuideTourStep[]}>) => {
            state[action.payload.name] = {
                currentStepIndex: 0,
                completed: false,
                steps: structuredClone(action.payload.steps)
            };
        },
        completeStep: (state, action: PayloadAction<string>) => {
            const tourName = action.payload;

            if (!state[tourName]) {
                notifyBugsnag(new Error("Unknown search guide tour name"), `Unknown search guide tour: ${tourName}`);
                return;
            }

            state[tourName].currentStepIndex++;

            if (state[tourName].currentStepIndex >= state[tourName].steps.length) {
                state[tourName].currentStepIndex = NaN;
                state[tourName].completed = true;
            }
        }
    }
});

export const {completeStep, initializeTour} = searchGuideTourSlice.actions;
