import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../../app/api/rp_api";
import {IRegionListMap} from "../../../region/types/IRegionListMap";
import {RegionType} from "../../../region/types/RegionType";
import {SEARCH_REGIONS_LIMIT} from "../../../search/constants/search_regions";
import {getIgnoredNotifyResponse} from "../../../store/utils/get_ignored_notify_response";

interface IRequestParams {
    region?: number | number[];
}

interface IResponse {
    results: IRegionListMap[];
}

const multiRegionListLink = apiV2ListLink.region.list(Scenario.REGION_LIST_MAP);

export const getMapRegion = rpApi.injectEndpoints({
    endpoints: (build) => ({
        getMapRegions: build.query<IResponse, IRequestParams>({
            queryFn: async (args, _api, _extraOptions, baseQuery) => {
                if (!args.region || (Array.isArray(args.region) && args.region.length === 0)) {
                    return {
                        data: {
                            results: []
                        }
                    };
                }

                const baseQueryResult = await baseQuery({
                    url: multiRegionListLink,
                    params: {
                        ...args,
                        type: [
                            RegionType.VOIVODESHIP,
                            RegionType.AGGREGATION,
                            RegionType.COUNTY,
                            RegionType.CITY,
                            RegionType.TOWN,
                            RegionType.DISTRICT,
                            RegionType.NEIGHBORHOOD,
                            RegionType.SURROUNDINGS
                        ],
                        page_size: SEARCH_REGIONS_LIMIT
                    }
                });

                if (baseQueryResult.error) {
                    if (!getIgnoredNotifyResponse(baseQueryResult.error, [400, 404]).ignoreNotify) {
                        return {
                            data: {
                                results: []
                            }
                        };
                    } else {
                        return {error: baseQueryResult.error};
                    }
                }

                return {data: baseQueryResult.data as IResponse};
            }
        })
    })
});

export const {useGetMapRegionsQuery} = getMapRegion;
import {apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {rpApi} from "../../../app/api/rp_api";
import {IRegionListMap} from "../../../region/types/IRegionListMap";
import {RegionType} from "../../../region/types/RegionType";
import {SEARCH_REGIONS_LIMIT} from "../../../search/constants/search_regions";
import {getIgnoredNotifyResponse} from "../../../store/utils/get_ignored_notify_response";

interface IRequestParams {
    region?: number | number[];
}

interface IResponse {
    results: IRegionListMap[];
}

const multiRegionListLink = apiV2ListLink.region.list(Scenario.REGION_LIST_MAP);

export const getMapRegion = rpApi.injectEndpoints({
    endpoints: (build) => ({
        getMapRegions: build.query<IResponse, IRequestParams>({
            queryFn: async (args, _api, _extraOptions, baseQuery) => {
                if (!args.region || (Array.isArray(args.region) && args.region.length === 0)) {
                    return {
                        data: {
                            results: []
                        }
                    };
                }

                const baseQueryResult = await baseQuery({
                    url: multiRegionListLink,
                    params: {
                        ...args,
                        type: [
                            RegionType.VOIVODESHIP,
                            RegionType.AGGREGATION,
                            RegionType.COUNTY,
                            RegionType.CITY,
                            RegionType.TOWN,
                            RegionType.DISTRICT,
                            RegionType.NEIGHBORHOOD,
                            RegionType.SURROUNDINGS
                        ],
                        page_size: SEARCH_REGIONS_LIMIT
                    }
                });

                if (baseQueryResult.error) {
                    if (!getIgnoredNotifyResponse(baseQueryResult.error, [400, 404]).ignoreNotify) {
                        return {
                            data: {
                                results: []
                            }
                        };
                    } else {
                        return {error: baseQueryResult.error};
                    }
                }

                return {data: baseQueryResult.data as IResponse};
            }
        })
    })
});

export const {useGetMapRegionsQuery} = getMapRegion;
