export const truncate = `
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

export const truncateMultiline = (lines: number) => `
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: -webkit-box;
    -webkit-line-clamp: ${lines};
    -webkit-box-orient: vertical;
    white-space: normal;
`;
export const truncate = `
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

export const truncateMultiline = (lines: number) => `
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: -webkit-box;
    -webkit-line-clamp: ${lines};
    -webkit-box-orient: vertical;
    white-space: normal;
`;
