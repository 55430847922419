import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {convertToCountryLatLngLiteral} from "@pg-mono/open-street-map";
import {getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {clientRpRequestMeta} from "../../../../client/client_rp_request_meta";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {fetchDetailPoi} from "../../../maps/actions/fetch_detail_poi";
import {IPoi} from "../../../maps/types/IPoi";
import {PoiType} from "../../../maps/utils/PoiType";
import {Country} from "../../../region/types/Country";

export type IPoiSet = Omit<IPoi, "tags">;

export interface PoiStatsItem {
    name: string;
    distance: number;
}

export interface NearestPoiTypeStats {
    count: number;
    items: PoiStatsItem[];
}

export interface IPoiStats {
    id: number;
    subway_poi?: IPoiSet;
    tram_poi?: IPoiSet;
    bus_poi?: IPoiSet;
    railway_poi?: IPoiSet;
}

export interface IPoiStatsResponse {
    id: number;
    poi:
        | (IPoiStats & {
              nearest_pois_count: Record<PoiType, NearestPoiTypeStats>;
          })
        | null;
}

export const fetchPropertyDetailPoi = (coordinates: [number, number], country: Country) => async (dispatch: Dispatch) => {
    const {lat, lng} = convertToCountryLatLngLiteral(coordinates, country);

    return dispatch(
        fetchDetailPoi(clientRpRequestMeta, {
            radius: 3000,
            latitude: lat,
            longitude: lng
        })
    );
};

const FETCH_OFFER_DETAIL_POI_STATS = "overpass/FETCH_OFFER_DETAIL_POI_STATS";
export const fetchOfferDetailPoiStatsTypes = createRequestActionTypes(FETCH_OFFER_DETAIL_POI_STATS);

export const fetchOfferDetailPoiStatsAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    dispatch({type: fetchOfferDetailPoiStatsTypes.start});
    // do not use offerId from url params, since it may be id of incorrect investment stage
    const offerId = ctx.prevResult.offer.id;

    const url = apiV2Link.offer.detail(Scenario.OFFER_DETAIL_POI_STATS, {offerId});
    return getRequest(ctx.meta, url).then((res: IPoiStatsResponse) => {
        dispatch({type: fetchOfferDetailPoiStatsTypes.success, result: res});
        return res;
    });
};
import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {convertToCountryLatLngLiteral} from "@pg-mono/open-street-map";
import {getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {clientRpRequestMeta} from "../../../../client/client_rp_request_meta";
import {IRPRequestMeta} from "../../../app/rp_request_meta";
import {fetchDetailPoi} from "../../../maps/actions/fetch_detail_poi";
import {IPoi} from "../../../maps/types/IPoi";
import {PoiType} from "../../../maps/utils/PoiType";
import {Country} from "../../../region/types/Country";

export type IPoiSet = Omit<IPoi, "tags">;

export interface PoiStatsItem {
    name: string;
    distance: number;
}

export interface NearestPoiTypeStats {
    count: number;
    items: PoiStatsItem[];
}

export interface IPoiStats {
    id: number;
    subway_poi?: IPoiSet;
    tram_poi?: IPoiSet;
    bus_poi?: IPoiSet;
    railway_poi?: IPoiSet;
}

export interface IPoiStatsResponse {
    id: number;
    poi:
        | (IPoiStats & {
              nearest_pois_count: Record<PoiType, NearestPoiTypeStats>;
          })
        | null;
}

export const fetchPropertyDetailPoi = (coordinates: [number, number], country: Country) => async (dispatch: Dispatch) => {
    const {lat, lng} = convertToCountryLatLngLiteral(coordinates, country);

    return dispatch(
        fetchDetailPoi(clientRpRequestMeta, {
            radius: 3000,
            latitude: lat,
            longitude: lng
        })
    );
};

const FETCH_OFFER_DETAIL_POI_STATS = "overpass/FETCH_OFFER_DETAIL_POI_STATS";
export const fetchOfferDetailPoiStatsTypes = createRequestActionTypes(FETCH_OFFER_DETAIL_POI_STATS);

export const fetchOfferDetailPoiStatsAtRoute = (ctx: IFetchContext<IRPRequestMeta>) => async (dispatch: Dispatch) => {
    dispatch({type: fetchOfferDetailPoiStatsTypes.start});
    // do not use offerId from url params, since it may be id of incorrect investment stage
    const offerId = ctx.prevResult.offer.id;

    const url = apiV2Link.offer.detail(Scenario.OFFER_DETAIL_POI_STATS, {offerId});
    return getRequest(ctx.meta, url).then((res: IPoiStatsResponse) => {
        dispatch({type: fetchOfferDetailPoiStatsTypes.success, result: res});
        return res;
    });
};
