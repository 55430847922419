const mapUserPreferences = "map/USER_PREFERENCES";

export const mapUserPreferencesActionTypes = {
    savePublicTransportChoice: `${mapUserPreferences}_SAVE_PUBLIC_TRANSPORT_CHOICE`
};

export const savePublicTransportChoice = (showPublicTransportOnMap: boolean) => {
    return {type: mapUserPreferencesActionTypes.savePublicTransportChoice, payload: {showPublicTransportOnMap}};
};
const mapUserPreferences = "map/USER_PREFERENCES";

export const mapUserPreferencesActionTypes = {
    savePublicTransportChoice: `${mapUserPreferences}_SAVE_PUBLIC_TRANSPORT_CHOICE`
};

export const savePublicTransportChoice = (showPublicTransportOnMap: boolean) => {
    return {type: mapUserPreferencesActionTypes.savePublicTransportChoice, payload: {showPublicTransportOnMap}};
};
