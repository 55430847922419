export const propertyGroupLink = "https://propertygroup.pl/";
export const opportunityListPGLink = `${propertyGroupLink}pracuj-z-nami/`;
export const anniversaryPGLink = `${propertyGroupLink}z-zycia-portalu/rynekpierwotny-pl-uczcil-10-lecie-istnienia-na-rynku/`;
export const bigDataLink = "https://bigdata.rynekpierwotny.pl/";
export const academyRealEstateLink = "https://akademia.re/";
export const developerAndMarketingLink = "https://dewelopermarketing.pl/";
export const competitionMonitoringLink = "https://bigdata.rynekpierwotny.pl/monitoring/";
export const misteriousCustomerLink = "https://tajemniczyklient.rynekpierwotny.pl/";
export const conferencesLink = "https://konferencje.rynekpierwotny.pl/";
export const promotionsForDevelopersLink =
    "https://bigdata-rp.user.com/landing/r2fjdi/?utm_source=rp&utm_medium=strona+glowna&utm_campaign=norowrocznypakietleadow";
export const propertyGroupLink = "https://propertygroup.pl/";
export const opportunityListPGLink = `${propertyGroupLink}pracuj-z-nami/`;
export const anniversaryPGLink = `${propertyGroupLink}z-zycia-portalu/rynekpierwotny-pl-uczcil-10-lecie-istnienia-na-rynku/`;
export const bigDataLink = "https://bigdata.rynekpierwotny.pl/";
export const academyRealEstateLink = "https://akademia.re/";
export const developerAndMarketingLink = "https://dewelopermarketing.pl/";
export const competitionMonitoringLink = "https://bigdata.rynekpierwotny.pl/monitoring/";
export const misteriousCustomerLink = "https://tajemniczyklient.rynekpierwotny.pl/";
export const conferencesLink = "https://konferencje.rynekpierwotny.pl/";
export const promotionsForDevelopersLink =
    "https://bigdata-rp.user.com/landing/r2fjdi/?utm_source=rp&utm_medium=strona+glowna&utm_campaign=norowrocznypakietleadow";
